import PropTypes from "prop-types";
import React from "react";

const Header = () => (
  <header>
    <div className="header-content">
      <h1 className="title-container">
        <span className="visually-hidden">elinoï</span>
        <svg className="logo-svg" viewBox="0 0 680.7 180.4">
          <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#logo"></use>
        </svg>
      </h1>

      <div>
        <a href="https://elinoi.com/?utm_source=fifty_startups&utm_medium=ref&utm_campaign=acceder_elinoi"
          target="_blank"
          rel="noreferrer noopener"
          className="site-access"
        >
          Accéder à elinoï.com
        </a>
      </div>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
